<template>
    <modal @close="$emit('close')">
        <template v-slot:title><i class="ri-pencil-line me-2 align-bottom text-muted"></i>{{ $t('Renaming') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="team-profile-img d-flex mb-4 " style="align-items: center;" >
                        <div class="avatar-md" >
                            <div class="avatar-title bg-soft-info bg-info-subtle text-info rounded fs-24" >
                                <i class="ri-folder-2-fill"></i>
                            </div>
                        </div>
                        <div class="team-content" style="margin-left: 15px;" >
                            <a class="member-name"> 
                                <h5 class="fs-16 mb-1">
                                    {{ form.name }}
                                </h5> 
                            </a>
                            <p class="text-muted member-designation mb-0">{{ form.createDatetime }}</p>
                        </div>
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15 fw-semibold">{{ $t('enterNewName') }}</h4>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="this.nameFile" >
                            <span class="input-group-text">{{ this.type }}</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="this.form.description" :placeholder="this.$t('desc')">
                        </div>
                    </div>
                    <!-- <div class="mb-3">
                        <h4 class="fs-15 fw-semibold">{{ $t('enterNewName') }}</h4>
                        <input type="text" class="form-control" v-model="this.nameFile">
                    </div>
                    <div class="mb-3">
                        <h4 class="fs-15 fw-semibold">{{ $t('fileType') }}</h4>
                        <input type="text" readonly class="form-control" v-model="this.type">
                    </div> -->
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <div class="pt-2">
            <button type="button" class="btn btn-success" v-on:click="edit">{{ $t('RenameItem') }}</button>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { fileManager } from "@/API"
import { storeS } from '../../../store';

let apiServe = new fileManager();

export default{
    props: ['objItem'],
    components: {
        modal
    },
    data(){
        return{
            form: {
                name: '',
                description: '',
            },
            type: '',
            nameFile: '',
        }
    },
    created(){
        this.form = this.objItem;
        storeS.checkModal.type = 'actionFM';
        storeS.checkModal.id = this.form.fileManagerId;
        this.type = '.' + this.form.name.split('.')[this.form.name.split('.').length - 1]
        this.nameFile = this.form.name.replace('.' + this.form.name.split('.')[this.form.name.split('.').length - 1], '')
    },
    methods: {
        edit(){
            console.log('fomr', this.form);
            this.form.name = this.nameFile.replace(' ', '_')  + this.type
            apiServe.renameFolder(this.form.fileManagerId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('SuccessfullyRenamed'))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        }
    }
}
</script>