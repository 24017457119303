<template>
    <PageHeader :title="title"/>

    <b-row>
        <!-- <b-col xl="3" v-show="hideMenu == false">
            <b-card>
                <div class="mx-n4 px-4 email-menu-sidebar-scroll" >
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <span class="fw-semibold fs-14">{{ $t('main') }}</span>
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body pt-0">
                                    <div class="mail-list mt-1" >
                                        <a role="button" :class="`collapsed fs-16 ${activeListItem == 'main' ? 'active' : ''}`" @click="getdata">
                                            <i class="ri-folder-2-line align-bottom me-2 fs-20"></i> <span class="file-list-link">{{ $t('MyFiles') }}</span>
                                        </a>
                                        <a role="button" :class="`collapsed fs-16 ${activeListItem == 'lastDown' ? 'active' : ''}`" @click="lastDownloads">
                                            <i class="bx bx-cloud-upload align-bottom me-2 fs-20"></i> <span class="file-list-link">{{ $t('LatestDownloads') }}</span>
                                        </a>
                                        <a role="button" :class="`collapsed fs-16 ${activeListItem == 'deleted' ? 'active' : ''}`" @click="getDeleted">
                                            <i class="bx bx-trash align-bottom me-2 fs-20"></i> <span class="file-list-link">{{ $t('Basket') }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion accordion-flush" id="accordionFlushExample1">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <span class="fw-semibold fs-14">{{ $t('Cooperation') }}</span>
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample1">
                                <div class="accordion-body pt-0">
                                    <div class="mail-list mt-1" >
                                        <a role="button" :class="`collapsed fs-16 ${activeListItem == 'shared' ? 'active' : ''}`" @click="getSharedWith">
                                            <i class="ri-team-fill align-bottom me-2 fs-20 "></i> <span class="file-list-link">{{ $t('sharedWithMe') }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion accordion-flush" id="accordionFlushExample3" v-if="favorites.length > 0">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <span class="fw-semibold fs-14">{{ $t('Favorites') }}</span>
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample3">
                                <div class="accordion-body pt-0">
                                    <div class="mail-list mt-1" >
                                        <template v-for="item in favorites" :key="item">
                                            <a role="button" :class="`collapsed fs-16 ${activeListItem == item.fileManagerId ? 'active' : ''}`" @click="openFolder(item, 'favorites')">
                                                <i class="ri-folder-2-line align-bottom me-2 fs-20"></i> <span class="file-list-link"> {{ item.name }}</span>
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-auto border-top pt-4">
                    <h6 class="fs-11 text-muted text-uppercase mb-3">{{ $t('Storage') }}</h6>
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                            <i class="ri-database-2-line fs-17"></i>
                        </div>
                        <div class="flex-grow-1 ms-3 overflow-hidden">
                            <div class="progress mb-2 progress-sm">
                                <div class="progress-bar bg-success" role="progressbar" :style="`width: ${dataDisk.Capacity}`" :aria-valuenow="`${dataDisk.Capacity}`" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <span class="text-muted fs-12 d-block text-truncate"><b>{{ dataDisk.Used }}</b> {{ $t('usedFrom') }} <b>{{ dataDisk.Size }}</b></span>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-col> -->

        <div :class='hideMenu == true && hideInfo == false ? "col-xl-12" : (hideMenu == false && hideInfo == false ? "col-xl-9" : (this.hideMenu == true && this.hideInfo == true ? "col-xl-9" : "col-xl-6"))' :key="this.componentKey">
            <b-card>
                <div class="row g-2 card-header mb-2" >
                    <div id="menuHider">
                        <div class="list-grid-nav hstack gap-1" >
                            <leftBar 
                                :favorites="favorites"
                                :dataDisk="dataDisk"
                                :activeListItem="activeListItem"
                                @getdata="getdata"
                                @lastDownloads="lastDownloads"
                                @getDeleted="getDeleted"
                                @openFolder="openFolder"
                                @getSharedWith="getSharedWith"
                            />
                            <button 
                                @click="breadcrumbs.length > 1 ? openFolder(this.breadcrumbs[this.breadcrumbs.length - 2]) : getdata()"
                                type="button" id="grid-view-button" :class="`btn btn-soft-secondary nav-link btn-icon fs-14 filter-button`" 
                                :disabled="breadcrumbs.length == 0"
                            >
                                <!-- кнопка назад -->
                                <i class="ri-arrow-left-circle-line fs-22"></i>
                            </button>
                            <button 
                                type="button" id="grid-view-button" :class="`btn btn-soft-secondary nav-link btn-icon fs-14 filter-button`" 
                                :disabled="oldBreadCrumbs.length == 0"
                                @click="backToFuture"
                            >
                                <!-- кнопка вперед -->
                                <i class="ri-arrow-right-circle-line fs-22"></i>
                            </button>
                        </div>
                    </div>
                    
                    <div id="search" >
                        <div class="search-box" >
                            <input type="text" class="form-control" :placeholder="$t('searc_inp')" v-model="objParams.search" @input="searchB"> 
                            <i class="ri-search-line search-icon"></i>
                        </div>
                    </div>
                    <!--end col-->
                    <div id="menu" >
                        <div class="list-grid-nav hstack gap-1" >
                            <button type="button" v-if="moveItem" id="grid-view-button" @click="pasteFileFolder()" :class="`btn btn-soft-secondary nav-link btn-icon fs-14`" ><i class="ri-folders-line fs-16"></i></button>
                            <button type="button" id="grid-view-button" :class="`btn btn-soft-info nav-link btn-icon fs-14 ${typeList == 1 ? 'active' : ''} filter-button`" @click="typeList = 1"><i class="ri-grid-fill"></i></button>
                            <button type="button" id="list-view-button" :class="`btn btn-soft-info nav-link  btn-icon fs-14 ${typeList == 0 ? 'active' : ''} filter-button`" @click="typeList = 0"><i class="ri-list-unordered"></i></button>
                            <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false" class="btn btn-soft-info btn-icon fs-14"><i class="ri-more-2-fill"></i></button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                <li><a class="dropdown-item copy" >{{ $t('SortbyDate') }}</a></li>
                                <li><a class="dropdown-item copy" >{{ $t('SortAlphabetically') }}</a></li>
                                <li><a class="dropdown-item copy" >{{ $t('SortbyType') }}</a></li>
                                <hr />
                                <li><a class="dropdown-item copy" @click="clearMoved">{{ $t('Clear') }}</a></li>
                            </ul>
                            <button type="button" id="dropdownMenuLink2" data-bs-toggle="dropdown" aria-expanded="false" class="btn btn-success btn-icon fs-14"><i class="ri-add-fill"></i></button>
                            <!-- <button class="btn btn-soft-secondary nav-link btn-icon fs-14 filter-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="this.hideInfo = !this.hideInfo"><i class='bx bx-info-circle'></i></button> -->
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                <li><a class="dropdown-item copy" @click="showCreatedFolder = true">{{ $t('createdFolder') }}</a></li>
                                <li><a class="dropdown-item copy" v-if="breadcrumbs.length > 0" @click="showUploadBox = true">{{ $t('downloadFiles') }}</a></li>
                            </ul>
                            <!-- <button class="btn btn-success " @click="showCreatedFolder = true" ><i class="ri-add-fill me-1 align-bottom"></i> {{ $t('createdFolder') }}</button> -->
                        </div>
                    </div>
                    <!-- <div class="row" style="margin-top: 16px;">
                        <a v-for="items in breadcrumbs" style="font-size: 20px;">{{ items }}</a>
                    </div> -->
                    <!--end col-->
                </div>
                
                <!-- хлібні -->
                <nav aria-label="breadcrumb" class="border-bottom pt-2 mb-3" v-if="breadcrumbs.length > 0">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item copy" @click="getdata()"><a ><i class="ri-home-5-fill"></i></a></li>
                        <template v-for="item in breadcrumbs" :key="item">
                            <li class="breadcrumb-item copy" @click="openFolder(item)" ><a >{{ item.name }}</a></li>
                        </template>
                        <div class="dropdown" style="padding-top: 3px;margin-left: 5px;" >
                            <button class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal icon-sm"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                            </button>
                            <!-- можливо потрібно підправити, коли можна буде створювати папки в папках -->
                            <div class="dropdown-menu dropdown-menu-end" >
                                <a class="dropdown-item"><i class="ri-star-line align-bottom me-2 text-muted"></i> {{ $t('AddtoFavorites') }}</a>
                                <a class="dropdown-item copy" @click="rename(folder)"><i class="ri-pencil-line align-bottom me-2 text-muted"></i> {{ $t('RenameItem') }}</a>
                                <a class="dropdown-item" @click="move(folder)"><i class="ri-folders-line align-bottom me-2 text-muted"></i> {{ $t('transfer') }}</a>
                                <a class="dropdown-item" @click="share(folder)"><i class="ri-share-line align-bottom me-2 text-muted"></i> {{ $t('toShare') }}</a>
                                <a class="dropdown-item"><i class="ri-download-cloud-2-line me-2 text-muted"></i> {{ $t('Download') }}</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" @click="dialogShow = true;selectedItem = folder"><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> {{ $t('Delete') }}</a>
                            </div>
                        </div>
                    </ol>
                </nav>
                

                <template v-if="typeList == 0">
                    <div id="teamlist" >
                        <!-- {{ item.parentFolderId == null || (breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].fileManagerId == item.parentFolderId : 'krya') || (breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].fileManagerId == item.fileManagerId : 'krya2') }} -->
                        <div class="team-list row list-view-filter" id="team-member-list" >
                            <template v-for="item in folders" :key="item">
                                <div class="col" >
                                    <div class="team-box border-bottom pb-2" >
                                        <!-- <div class="team-cover" ><img src="assets/images/small/img-9.jpg" alt="" class="img-fluid" /></div> -->
                                        <div class="p-2">
                                            <div class="row align-items-center team-row" @dblclick="item.hasOwnProperty('type') && item.type != 'folder' ? switchEventFile(item) : openFolder(item)" style="cursor: pointer;">
                                                <div class="col team-settings" >
                                                    <div class="row" >
                                                        <div class="col text-end dropdown" >
                                                            <a data-bs-toggle="dropdown" aria-expanded="false"> <i class="ri-more-fill fs-17"></i> </a>
                                                            <ul class="dropdown-menu dropdown-menu-end">
                                                                <li class="copy">
                                                                    <a class="dropdown-item edit-list" @click="openFolder(item)"><i class="ri-folder-open-line me-2 align-bottom text-muted"></i>{{ $t('Open') }}</a>
                                                                </li>
                                                                <li class="copy">
                                                                    <a class="dropdown-item edit-list" @click="item.favorite == 0 ? addToFavorites(item) : removeFromFavorites(item)"><i class="ri-star-line me-2 align-bottom text-muted"></i>{{ item.favorite == 0 ? $t('AddtoFavorites') : $t('RemoveFromFavorites') }}</a>
                                                                </li>
                                                                <li class="copy" v-if="item.edit">
                                                                    <a class="dropdown-item edit-list" @click="rename(item)" ><i class="ri-pencil-line me-2 align-bottom text-muted"></i>{{ $t('RenameItem') }}</a>
                                                                </li>
                                                                <li class="copy" v-if="item.edit">
                                                                    <a class="dropdown-item edit-list" @click="move(item)"><i class="ri-folders-line me-2 align-bottom text-muted"></i>{{ $t('transfer') }}</a>
                                                                </li>
                                                                <li class="copy">
                                                                    <a class="dropdown-item edit-list" @click="share(item)"><i class="ri-share-line me-2 align-bottom text-muted"></i>{{ $t('toShare') }}</a>
                                                                </li>
                                                                <li class="copy" v-if="item.edit">
                                                                    <a class="dropdown-item remove-list" @click="dialogShow = true;selectedItem = item"><i class="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>{{ $t('Delete') }}</a>
                                                                </li>
                                                                <li class="copy" v-if="item.hasOwnProperty('type')">
                                                                    <a class="dropdown-item remove-list" @click="downloadFile(item)"><i class="ri-download-cloud-2-line me-2 align-bottom text-muted"></i>{{ $t('Download') }}</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col" >
                                                    <div class="team-profile-img" >
                                                        <div class="avatar-lg" >
                                                            <div :class="`avatar-title bg-soft-${mutateFileManager(item.type).color} bg-${mutateFileManager(item.type).color}-subtle text-${mutateFileManager(item.type).color} rounded fs-24`" >
                                                                <i :class="`${mutateFileManager(item.type).icon}`"></i>
                                                            </div>
                                                        </div>
                                                        <div class="team-content" >
                                                            <a class="member-name" > 
                                                                <h5 class="fs-16 mb-1">{{ item.name || item.folderName || item.fileName }}</h5> 
                                                            </a>
                                                            <p class="text-muted member-designation mb-0">{{ item.createDatetime || item.createFileDatetime }}</p>
                                                            <p class="text-muted member-designation mb-0">{{ item.createDatetime || item.createFileDatetime }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-lg-2 col" >
                                                    <div class="text-end" ><a href="pages-profile.html" class="btn btn-light view-btn">View Profile</a></div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                </template>
                <template v-if="typeList == 1">
                    <div class="row" id="folderlist-data" >
                        <template v-for="item in folders" :key="item">
                            <!-- {{ breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : 'меньше 0' }} -->
                            <!-- <div class="col-xxl-3 col-6 folder-card" v-if="item.parentFolderId == null || '1' == '0'"> -->
                            <div :class="`col-xl-${hideInfo ? '6' : '4'} col-md-6 col-xs-12 folder-card`" v-if="item.parentFolderId == null || (breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].fileManagerId == item.parentFolderId : '') || (breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].fileManagerId == item.fileManagerId : '')">
                                <div class="card bg-light shadow-none" id="folder-1" >
                                    <div :class="`card-body copy ${item.fileManagerId == currentFile.fileManagerId ? 'active' : ''}`" @dblclick="item.hasOwnProperty('type') && item.type != 'folder' ? switchEventFile(item) : openFolder(item)" >
                                        <div class="d-flex mb-1" >
                                            <div class="form-check form-check-danger mb-3 fs-15 flex-grow-1" >
                                                <!-- <input class="form-check-input" type="checkbox" value="" id="folderlistCheckbox_1" checked="">
                                                <label class="form-check-label" for="folderlistCheckbox_1"></label> -->
                                            </div>
                                            <div class="dropdown" >
                                                <button class="btn btn-ghost-primary btn-icon btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ri-more-2-fill fs-16 align-bottom"></i>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end">
                                                    <li class="copy">
                                                        <a class="dropdown-item edit-list" @click="openFolder(item)" ><i class="ri-folder-open-line me-2 align-bottom text-muted"></i>{{ $t('Open') }}</a>
                                                    </li>
                                                    <li class="copy">
                                                        <a class="dropdown-item edit-list" @click="item.favorite == 0 ? addToFavorites(item) : removeFromFavorites(item)"><i class="ri-star-line me-2 align-bottom text-muted"></i>{{ item.favorite == 0 ? $t('AddtoFavorites') : $t('RemoveFromFavorites') }}</a>
                                                    </li>
                                                    <li class="copy" v-if="item.edit">
                                                        <a class="dropdown-item edit-list" @click="rename(item)"><i class="ri-pencil-line me-2 align-bottom text-muted"></i>{{ $t('RenameItem') }}</a>
                                                    </li>
                                                    <li class="copy" v-if="item.edit">
                                                        <a class="dropdown-item edit-list" @click="move(item)" ><i class="ri-folders-line me-2 align-bottom text-muted"></i>{{ $t('transfer') }}</a>
                                                    </li>
                                                    <li class="copy">
                                                        <a class="dropdown-item edit-list" @click="share(item)" ><i class="ri-share-line me-2 align-bottom text-muted"></i>{{ $t('toShare') }}</a>
                                                    </li>
                                                    <li class="copy" v-if="item.edit">
                                                        <a class="dropdown-item remove-list" @click="dialogShow = true;selectedItem = item"><i class="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>{{ $t('Delete') }}</a>
                                                    </li>
                                                    <li class="copy" v-if="item.hasOwnProperty('type') && item.type != 'folder'">
                                                        <a class="dropdown-item remove-list" @click="downloadFile(item)"><i class="ri-download-cloud-2-line me-2 align-bottom text-muted"></i>{{ $t('Download') }}</a>
                                                    </li>
                                                    <div class="dropdown-divider"></div>
                                                    <li class="copy" >
                                                        <a v-if="checkSize() > 1199" class="dropdown-item remove-list" @click="this.setCurrentFile(item);hideInfo = true"><i class="ri-information-line me-2 align-bottom text-muted"></i>{{ $t('Information') }}</a>
                                                        <a v-else class="dropdown-item remove-list" @click="this.setCurrentFile(item)" data-bs-toggle="offcanvas" data-bs-target="#fileInfoBar" aria-controls="fileInfoBar"><i class="ri-information-line me-2 align-bottom text-muted"></i>{{ $t('Information') }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="text-center" @click="this.setCurrentFile(item)">
                                            <div class="mb-2" >
                                                <i :class="`${mutateFileManager(item.type).icon} align-bottom text-${mutateFileManager(item.type).color} display-5`"></i>
                                            </div>
                                            <h6 class="fs-15 folder-name fw-semibold">{{ item.name || item.folderName || item.fileName }}</h6>
                                            <!-- <h6 class="fs-12 descriptionFile" v-html="item.description"></h6> -->
                                            <span class="fs-12 text-muted"><i class="mdi mdi-account-circle text-muted me-1"></i>{{ nameWorker(item.createWorkerId) }}</span><br />
                                            <span class="fs-12 text-muted">{{ item.createDatetime || item.createFileDatetime }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </b-card>
        </div>

        <b-col xl="3" v-if="this.hideInfo == true" style="padding: 0">
            <b-card style="margin: 0;" v-if="currentFile != ''">
                <div id="file-overview" class="h-100" style="display: block;">
                    <!-- шапка -->
                    <div class="d-flex h-100 flex-column" >
                        <div class="d-flex align-items-center pb-3 border-bottom border-bottom-dashed mb-3 gap-2" >
                            <h5 class="flex-grow-1 fw-semibold mb-0">{{ currentFile.type == 'folder' ? $t('FolderPreview') : $t('FilePreview') }}</h5>
                            <div >
                                <button type="button" @click="currentFile.favorite == 0 ? addToFavorites(currentFile) : removeFromFavorites(currentFile)" :class="`btn btn-ghost-primary btn-icon btn-sm fs-16 favourite-btn ${currentFile.favorite == 0 ? '' : 'active'}`">
                                    <i class="ri-star-fill align-bottom"></i>
                                </button>
                                <button type="button" @click="hideInfo = false" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-overview">
                                    <i class="ri-close-fill align-bottom"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- зображення  -->
                    <div class="pb-3 border-bottom border-bottom-dashed mb-3" >
                        <div class="file-details-box bg-light p-3 text-center rounded-3 border border-light mb-3" >
                            <div class="display-4 file-icon" >
                                <i class="align-bottom " :class="mutateFileManager(currentFile.type).icon + ' text-' + mutateFileManager(currentFile.type).color"></i>
                            </div>
                        </div>
                        <button type="button" class="btn btn-icon btn-sm btn-ghost-success float-end fs-16" @click="share(this.currentFile)"><i class="ri-share-forward-line"></i></button>
                        <h5 class="fs-16 mb-1 file-name">{{ currentFile.name }}</h5>
                        <p class="text-muted mb-0 fs-12"><span class="create-date">{{ currentFile.createDatetime }}</span></p>
                    </div>

                    <!-- опис  -->
                    <div >
                        <h5 class="fs-12 text-uppercase text-muted mb-3">{{ currentFile.type == 'folder' ? $t('descFolder') : $t('descFile') }}:</h5>
                        <div class="table-responsive" >
                            <table class="table table-borderless table-nowrap table-sm">
                                <tbody>
                                    <tr>
                                        <th scope="row" style="width: 35%;">{{ $t('fileName') }}:</th>
                                        <td class="file-name">{{ currentFile.name }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{{ $t('сreatedT') }} :</th>
                                        <td class="create-date">{{ currentFile.createDatetime }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{{ $t('created') }} :</th>
                                        <td class="create-date">{{ nameWorker(currentFile.createWorkerId) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div >
                            <h5 class="fs-12 text-uppercase text-muted mb-3">{{ $t('WhoHasAccess') }}:</h5>
                            <div class="table-responsive" >
                                <table class="table table-borderless table-nowrap table-sm">
                                    <tbody>
                                        <template v-for="item in currentFile.sharePerms" :key="item">
                                            <tr>
                                                <th scope="row" style="width: 35%;">{{ nameWorker(item.workerId) }}</th>
                                                <td :class="`share-name fs-12 badge badge-soft-${item.perm == 1 ? 'info' : 'secondary' }`">{{ item.perm == 1 ? $t('ViewandDownload') : $t('EditingandUploading') }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- кнопки -->
                    <div class="mt-auto border-top border-top-dashed py-3" >
                        <div class="hstack gap-2" >
                            <button type="button" v-if="(currentFile.hasOwnProperty('type') && currentFile.type != 'folder') || (currentFile.parentFolderId != null)" @click="downloadFile(currentFile)" class="btn btn-soft-info w-100"><i class="ri-download-2-line align-bottom me-1"></i> {{ $t('Download') }}</button>
                            <button type="button" @click="dialogShow = true;selectedItem = currentFile" class="btn btn-soft-danger w-100 remove-file-overview"><i class="ri-close-fill align-bottom me-1"></i> {{ $t('Delete') }}</button>
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card v-else>{{ this.$t('Null') }}</b-card>
            <!-- <b-card style="margin: 0" v-if="currentFile != ''">
                <div class="row">
                    <div class="col-2">
                        <i :class="`${mutateFileManager(currentFile.type).icon} align-bottom text-${mutateFileManager(currentFile.type).color} display-5`" style="font-size: 16px"></i>
                    </div>
                    <div class="col-10" style="font-size: 16px">{{ currentFile.name || currentFile.folderName || currentFile.fileName }}</div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="fs-16" style="color: green">{{ this.$t('createDate') }} <br>
                            <span style="color: initial">{{ this.currentDate(currentFile.createDatetime).day + ' ' + this.$t(`${this.currentDate(currentFile.createDatetime).month}`) + ', ' +this.currentDate(currentFile.createDatetime).year }}</span>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="fs-16" style="color: green; margin-bottom: 0;">{{ this.$t('desc') }}:
                        </p>
                        <span v-html="currentFile.description" class="description"></span>
                    </div>
                </div>
            </b-card>
            <b-card v-else>{{ this.$t('Null') }}</b-card> -->
        </b-col>
    </b-row>

    <rightBar 
        :currentFile="currentFile"
        :key="this.componentKey"
        @addToFavorites="addToFavorites"
        @removeFromFavorites="removeFromFavorites"
        @deleteItem="deleteItem"
        @share="share"
    />

    <!-- створення папки -->
    <createdFolder 
        v-if="showCreatedFolder"
        @close="closeModal"
        :permWorkers="this.folder.sharePerms"
        :parrentId="this.folder.fileManagerId"
    />
    <!-- створення папки -->

     <!-- перейменувати -->
     <renameBox 
        v-if="showRenameBox"
        @close="closeModal"
        :objItem="selectedItem"
    />
    <!-- перейменувати -->

     <!-- {{ $t('transfer') }} -->
     <moveBox 
        v-if="showMoveBox"
        :folderTree="folderTree"
        @close="showMoveBox = false"
        :objItem="selectedItem"
    />
    <!-- {{ $t('transfer') }} -->

    <!-- поділитись -->
    <shareBox 
        v-if="showShareBox"
        @close="closeModal"
        :objItem="selectedItem"
    />
    <!-- поділитись -->

    <!-- завантажити файли -->
    <uploadBox 
        v-if="showUploadBox"
        @close="closeModal"
        :objItem="selectedItem"
        :pasteFile="pasteFile"
    />
    <!-- завантажити файли -->
    
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove();showImg = false" 
    />

    <modallg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
            <button class="gdelete gbtn" @click="dialogShow = true;selectedItem = gFile" ><i class="ri-delete-bin-fill"></i></button>
            <!-- <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
            <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button> -->
        </template>
    </modallg>

</template>

<script>
import PageHeader from "@/components/page-header";
import createdFolder from "./action/createdFolder"
import renameBox from "./action/rename.vue"
import moveBox from "./action/move.vue"
import shareBox from "./action/share.vue"
import uploadBox from "./action/upload.vue"
import modalremove from '@/components/modal/modalremove'
import modallg from '@/components/modal/img'
import leftBar from './leftBar'
import rightBar from './rightBar'
import { fileManager } from "@/API"
import { storeS } from "@/store";
import {eventB} from '@/main'
import { mutateFileManager, getMonth } from "@/usabilityScripts/globalMutate.js"
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiServe = new fileManager();

export default{
    components: {
        PageHeader,
        createdFolder,
        renameBox,
        moveBox,
        shareBox,
        modalremove,
        uploadBox,
        modallg,
        leftBar,
        rightBar
    },
    data(){
        return{
            currentFile: '',
            hideInfo: false,
            fileManagerId: '',
            inFolder: 'false',
            breadcrumbs: [],
            oldBreadCrumbs: [],
            componentKey: 0,
            hideMenu: true,
            title: this.$t('fileManager'),
            searchTime: '',
            form: '',
            typeList: 1, //1 - grid, 0 - list
            showCreatedFolder: false,
            showRenameBox: false,
            showMoveBox: false,
            showShareBox: false,
            showUploadBox: false,
            dialogShow: false,
            objParams:{
                page: '1',
                pagelimit: '200',
                search: ''
            },
            inFolders: [],
            folders: [],
            favorites: [],
            // files: [
            //     {
            //         id: '1',
            //         type: 'folder',
            //         attributes: {
            //             created_at: '2023-08-22',
            //             deleted_at: '',
            //             filesize: '',
            //             isTeamFolder: '',
            //             items: '',
            //             name: "Shared Folder",
            //             trashed_items: 4,
            //             updated_at: "2023-08-25"
            //         },
            //         relationships: [

            //         ]
            //     },
            //     {
            //         id: '2',
            //         type: 'folder',
            //         attributes: {
            //             created_at: '2023-07-19',
            //             deleted_at: '',
            //             filesize: '',
            //             isTeamFolder: '',
            //             items: '',
            //             name: "Best folder",
            //             trashed_items: 4,
            //             updated_at: "2023-08-01"
            //         },
            //         relationships: [

            //         ]
            //     },
            // ],
            folder: '',
            selectedItem: '',
            pasteFile: '',
            showImg: false,
            gFile: {
                fileId: '',
                src: '',
                type: '',
                fileName: ''
            },
            ifPrevimg: '',
            ifNextimg: '',
            dataDisk: '',
            folderTree: '',
            moveItem: '',
            activeListItem: 'main'
        }
    },
    created(){
        this.getdata();
        document.onpaste = function(pasteEvent) {
            console.log(sessionStorage.getItem('inFolder'))
            // рассмотрим первый элемент (можно легко расширить для нескольких элементов)
            if(sessionStorage.getItem('inFolder') == 'true') {
                var item = pasteEvent.clipboardData.items[0];
                if (item.type.indexOf("text") !== 0){
                    var blob = item.getAsFile();
                    var reader = new FileReader();
                    reader.onload = function(event) {
                        eventB.emit('openImage', {img: blob})
                    };
                
                    reader.readAsDataURL(blob);
                }
            } else {
                console.log((sessionStorage.getItem('inFolder')))
            }
        }
    },
    mounted() {
        // вставити файли з буферу обміну
        eventB.off('openImage')
        eventB.on('openImage', data => {
            // this.selectedItem = data.img
            this.pasteFile = data.img
            this.showUploadBox = true
        })

        if(localStorage.getItem('moveFile')){
            this.moveItem = JSON.parse(localStorage.getItem('moveFile'));
        }

        this.eventBus.off('updateFilemanager');
        this.eventBus.on('updateFilemanager', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    methods: {
        currentDate(e) {
            var date = new Date(e);
            var dateStr = {
                day: ("00" + (date.getDate())).slice(-2),
                month: (this.month(date.getMonth())),
                year: date.getFullYear()
            }
            return dateStr
        },
        month(e) {
            return getMonth(e)
        },
        setCurrentFile(e){
            this.currentFile = e
        },
        backToFuture() {
            this.openFolder(this.oldBreadCrumbs[this.oldBreadCrumbs.length-1][0])
            this.oldBreadCrumbs = this.oldBreadCrumbs.pop()
        },
        getdata(){
            this.folder = ''
            apiServe.getFolders(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.folders = result.data.items;
                    // this.folderTree = result.data.items;
                    this.breadcrumbs = []
                    this.backButton = false
                    this.inFolder = 'false';
                    this.dataDisk = result.data.diskSpace;
                    this.favorites = result.data.favorites;
                    this.activeListItem = 'main';
                    if(this.currentFile){
                        this.updateCurrentFile();
                    }
                    sessionStorage.setItem('inFolder', this.inFolder)
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        getSharedWith(){
            apiServe.getSharedWith().then(result => {
                if(result.status == 'done'){
                    this.folders = result.data.items;
                    // this.folderTree = result.data.items;
                    this.breadcrumbs = []
                    this.backButton = false
                    this.inFolder = 'false';
                    this.dataDisk = result.data.diskSpace;
                    this.favorites = result.data.favorites;
                    this.activeListItem = 'shared';
                    sessionStorage.setItem('inFolder', this.inFolder)
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        getDeleted(){
            this.folders = []
            apiServe.getDeleted().then(result => {
                if(result.status == 'done'){
                    this.folders = result.data.items;
                    // this.folderTree = result.data.items;
                    this.breadcrumbs = []
                    this.backButton = false
                    this.inFolder = 'false';
                    this.dataDisk = result.data.diskSpace;
                    this.favorites = result.data.favorites;
                    this.activeListItem = 'deleted'
                    sessionStorage.setItem('inFolder', this.inFolder)
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        lastDownloads(){
            apiServe.lastDownloads().then(result => {
                if(result.status == 'done'){
                    this.folders = result.data.items;
                    // this.folderTree = result.data.items;
                    this.breadcrumbs = []
                    this.backButton = false
                    this.inFolder = 'false';
                    this.dataDisk = result.data.diskSpace;
                    this.favorites = result.data.favorites;
                    this.activeListItem = 'lastDown'
                    sessionStorage.setItem('inFolder', this.inFolder)
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        closeModal(){
            this.showCreatedFolder = false;
            this.showRenameBox = false;
            this.showUploadBox = false;
            this.showShareBox = false
            this.dialogShow = false;
            this.selectedItem = false;
            if(this.inFolder == true){
                this.openFolder(this.breadcrumbs[this.breadcrumbs.length-1])
            } else {
                this.getdata();
                storeS.checkModal.type = '';
                storeS.checkModal.id = '';
            }
        },
        openFolder(e, f){
            console.log('e', e.name.split('.'), 'f', f)
            if(e.name.split('.')[1]) {
                this.downloadFile(e)
                return
            }
            //відкриття папки
            apiServe.openFolder(e.fileManagerId, this.objParams).then(result => {
                if(result.status == 'done'){
                    this.folders = result.data.items
                    // .filter(item => item.type == 'folder')
                    // if(f == 'favorites'){
                    //     this.activeListItem = e.fileManagerId
                    // }
                    // if(result.data.items.length > 0){
                    //     var itemFiles = result.data.items.filter(item => item.type != 'folder')
                    //     for(var i in itemFiles){
                    //         this.folders.push(itemFiles[i]);
                    //     }
                    // }
                    
                    // this.inFolders = result.data.folders;

                    this.folder = e;

                    this.inFolder = true
                    sessionStorage.setItem('inFolder', this.inFolder)

                    if(this.breadcrumbs.length > 0){
                        console.log(e.fileManagerId, this.breadcrumbs.indexOf(e.fileManagerId), this.breadcrumbs)
                        
                        if(this.breadcrumbs.find(el => el.fileManagerId == e.fileManagerId) == undefined) {
                            this.breadcrumbs.push(e)
                        } else {
                            // для кнопки вперед зберігаємо минулі положення
                            this.oldBreadCrumbs.push(this.breadcrumbs) 
                            // для кнопки вперед зберігаємо минулі положення
                            this.breadcrumbs = this.breadcrumbs.splice(0, this.breadcrumbs.indexOf(e)+1)
                        }
                    } else {
                        this.breadcrumbs.push(e)
                    }

                    if(this.currentFile){
                        this.updateCurrentFile();
                    }
                    
                    this.selectedItem = this.breadcrumbs[this.breadcrumbs.length - 1]
                    this.backButton = true
                }
            })
        },
        rename(e){
            //перейменування файлу/папки
            this.selectedItem = e;
            this.showRenameBox = true
        },
        move(e){
            //перемістити файли/папки
            this.moveItem = e;
            localStorage.setItem('moveFile',  JSON.stringify(e))
            this.$toast.info(this.$t('FilefolderCopied'))
            // this.showMoveBox = true
        },
        clearMoved(){
            this.moveItem = '';
            localStorage.removeItem('moveFile')
            this.$toast.warning(this.$t('Cleared'))
        },
        pasteFileFolder(){
            console.log('t', this);
            if(this.moveItem){
                var moveItem = {
                    toFolderId: this.folder.fileManagerId
                }
                apiServe.move(this.moveItem.fileManagerId, moveItem).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('MovedfileFolder'))
                        this.moveItem = '';
                        this.closeModal();
                        if(localStorage.getItem('moveFile')) {
                            localStorage.removeItem('moveFile')
                        }
                    } else {
                        if(this.folder.fileManagerId === '') {
                            this.$toast.error(this.$t('haveNotFolder'))
                            return
                        } else  {
                            this.$toast.error(this.$t('error'))
                        }
                    }
                })
            }
        },
        share(e){
            //поширити папки
            this.selectedItem = e;
            this.showShareBox = true
        },
        addToFavorites(e){
            apiServe.addToFavorites(e.fileManagerId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FilefolderAddedFavorites'))
                    this.closeModal();
                }
            })
        },
        removeFromFavorites(e){
            apiServe.removeFromFavorites(e.fileManagerId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FileFolderRemovedFavorites'))
                    this.closeModal();
                }
            })
        },
        updateCurrentFile(){
            this.folders.forEach(element => {
                if(element.fileManagerId == this.currentFile.fileManagerId){
                    this.currentFile = element;
                }
            });
        },
        deleteItem(e){
            this.selectedItem = e
            this.dialogShow = true
        },
        remove(){
            if(this.activeListItem != 'deleted'){
                if(this.selectedItem.parentFolderId == null || this.selectedItem.type == 'folder'){
                    apiServe.deleteFolder(this.selectedItem.fileManagerId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Removed'))
                            this.selectedItem = '';
                            this.dialogShow = false;
                            this.closeModal();
                        } else {
                            this.$toast.error(this.$t('err'))
                        }
                    })
                } else {
                    apiServe.deleteFile(this.selectedItem.fileManagerId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Removed'))
                            this.selectedItem = '';
                            this.dialogShow = false;
                            this.closeModal();
                        } else {
                            this.$toast.error(this.$t('err'))
                        }
                    })
                }
            } else {
                if(this.selectedItem.parentFolderId == null || this.selectedItem.type == 'folder'){
                    apiServe.fullDeleteFolder(this.selectedItem.fileManagerId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Removed'))
                            this.selectedItem = '';
                            this.dialogShow = false;
                            this.closeModal();
                        } else {
                            this.$toast.error(this.$t('err'))
                        }
                    })
                } else {
                    apiServe.fullDeleteFile(this.selectedItem.fileId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Removed'))
                            this.selectedItem = '';
                            this.dialogShow = false;
                            this.closeModal();
                        } else {
                            this.$toast.error(this.$t('err'))
                        }
                    })
                }
            }
        },
        switchEventFile(item){
            if(item.type == "image/jpeg" || item.type == "image/png" || item.type == "video/quicktime" || item.type == "video/mp4" || item.type == "image/heic" || item.type == "video/quicktime" ){
                this.getFile(item);
            } else {
                this.downloadFile(item);
            }
        },
        getFile(item){
            var id = item.fileManagerId ? item.fileManagerId : item;

            apiServe.getFile(id).then(result => {
                // if(result != ''){
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    this.gFile.fileManagerId = id;
                    this.gFile.src = url;
                    this.gFile.type = item.type;
                    this.gFile.fileName = item.fileName
                    this.showImg = true;
                    storeS.uploadPercentage = 0;
                // }
            })
        },
        downloadFile(item){ 
            apiServe.getFile(item.fileManagerId, item.type)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.name);
                    document.body.appendChild(link);
                    link.click();
                    storeS.uploadPercentage = 0;
                })
                .catch((error) => console.log(error));
        },
        prevOpen(e){
            var current_id = e.fileId;
            var prev_id = ""; 

            for(var value in this.folders){
                if(this.folders[value].fileId == current_id){
                    prev_id = this.folders[Number(value)-Number('1')];
                }
            }
            this.getFile(prev_id);
        },
        nextOpen(e){
            var current_id = e.fileId;
            var next_id = "";
            for(var value in this.folders){
                if(this.folders[value].fileId == current_id){
                    next_id = this.folders[Number(value)+Number('1')];
                }
            }
            this.getFile(next_id);
        },
        searchB(){
            clearTimeout(this.searchTime)
            // this.objParams.search = this.objParams.search
            this.searchTime = setTimeout(() => this.getdata(), 500)
        },
        checkSize(){
            var wSize = window.innerWidth
            return wSize
        },
        mutateFileManager(e){
            return mutateFileManager(e)
        },
        nameWorker(item){
            return nameWorker(item)
        },
    },
    computed: {
        perms(){
            return storeS.perms
        }
    }
}
</script>

<style>
#menuHider {
    display: grid;
    align-items: center;
    justify-content: start;
}  
#menu {
    display: grid;
    align-items: center;
    justify-content: end;
}
@media all and (min-width: 1750px) {    
    #menuHider {
        width: 30% !important;
    }  
    #search { 
        width: 55% !important;
    } 
    #menu {
        width: 15% !important
    }
}

@media (min-width: 1250px) and (max-width: 1749px) {
    #menuHider {
        width: 30% !important;
    }  
    #search { 
        width: 50% !important;
    } 
    #menu {
        width: 20% !important
    }
}

@media (min-width: 1250px) and (max-width: 1749px) {
    #menuHider {
        width: 30% !important;
    }  
    #search { 
        width: 50% !important;
    } 
    #menu {
        width: 20% !important
    }
}

@media (min-width: 1090px) and (max-width: 1250px) {
    #menuHider {
        width: 20% !important;
    }  
    #search { 
        width: 55% !important;
    } 
    #menu {
        width: 25% !important
    }
}

@media (min-width: 867px) and (max-width: 1089px) {
    #menuHider {
        width: 30% !important;
    }  
    #search { 
        width: 35% !important;
    } 
    #menu {
        width: 35% !important
    }
}

@media (min-width: 768px) and (max-width: 866px) {
    #menuHider {
        width: 100% !important;
    }  
    #search { 
        width: 100% !important;
    } 
    #menu {
        width: 100% !important;
        justify-content: start
    }
}

@media (min-width: 697px) and (max-width: 767px) {
    #menuHider {
        width: 30% !important;
    }  
    #search { 
        width: 40% !important;
    } 
    #menu {
        width: 30% !important;
    }
}

@media (max-width: 696px) {
    #menuHider {
        width: 100% !important;
    }  
    #search { 
        width: 100% !important;
    } 
    #menu {
        width: 100% !important;
        justify-content: start
    }
}
span.description > *{
    margin: 0;
    margin-left: 10px;
    color: initial;
    font-size: 18px
}
/* .card .active {
    background: #349aeda8;
    transition: .3s;
} */
</style>