<template>
    <modal @close="$emit('close')">
        <template v-slot:title><i class="ri-upload-cloud-2-line me-2 align-bottom text-muted"></i>{{ $t('Download') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <files
                :fileFromCtrlV="this.pasteFiles"
                :fromUpload="'filemanager'"
                @uploadFiles="uploadFiles"
            />
        </template>
        <template v-slot:footer-bottom >
            <!-- <div class="pt-2">
                <button type="button" class="btn btn-success" v-on:click="upload">{{ $t('Download') }}</button>
            </div> -->
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import files from '@/components/files/index'
import { fileManager } from "@/API"
import { storeS } from '@/store';

let apiServe = new fileManager();

export default{
    props: ['objItem','pasteFile'],
    components: {
        modal,
        files
    },
    data(){
        return{
            form: {
                folderName: ''
            },
            file: '',
            objParams:{
                page: '1',
                pagelimit: '200',
                search: ''
            },
            pasteFiles: ''
        }
    },
    created(){
        if(this.objItem) {
            this.file = this.objItem
        }
        if(this.pasteFile){
            this.pasteFiles = this.pasteFile
        }
        this.form = this.objItem;

        storeS.checkModal.type = 'actionFM';
        storeS.checkModal.id = this.form.fileManagerId;
    },
    methods: {
        getdata(){
            apiServe.getFolders(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.folders = result.data.items;
                    this.breadcrumbs = []
                    this.backButton = false
                    this.inFolder = 'false';
                    sessionStorage.setItem('inFolder', this.inFolder)
                }
            })
        },
        uploadFiles(obj, formData){
            apiServe.uploadFiles(this.form.fileManagerId, formData).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FileUploadedSuccessfully'));
                    storeS.uploadPercentage = 0;
                    this.$emit('close');
                } else if(result.status == 'error' && result.error[0] == 'access denied'){
                    storeS.uploadPercentage = 0;
                    this.$toast.error(this.$t('don`t_rights'))
                } else {
                    storeS.uploadPercentage = 0;
                    this.$toast.error(this.$t('err'))
                }
            })
        }
    }
}
</script>