<template>
    <modal @close="$emit('close')">
        <template v-slot:title><i class="ri-share-line me-2 align-bottom text-muted"></i>{{ $t('toShare') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="team-profile-img d-flex mb-4 " style="align-items: center;" >
                        <div class="avatar-md" >
                            <div class="avatar-title bg-soft-info bg-info-subtle text-info rounded fs-24" >
                                <i class="ri-folder-2-fill"></i>
                            </div>
                        </div>
                        <div class="team-content" style="margin-left: 15px;" >
                            <a class="member-name"> 
                                <h5 class="fs-16 mb-1">
                                    {{ form.name }}
                                </h5> 
                            </a>
                            <p class="text-muted member-designation mb-0">{{ form.createDatetime }}</p>
                        </div>
                    </div>
                    <!-- <div class="mb-3">
                        <h4 class="fs-15 fw-semibold">{{ $t('accessRights') }}</h4>
                        <select class="form-control" v-model="permFolder.perm">
                            <option v-for="item in permlist" :key="item" :value="item.value">{{ item.label }}</option>
                        </select>
                    </div> -->
                </b-col>
                <b-col lg="12">
                    <!-- <div class="search-box mb-3">
                        <input type="text" class="form-control" v-model="search" :placeholder="$t('searc_inp')">
                        <i class="ri-search-line search-icon"></i>
                    </div> -->
                    <div class="mb-3">
                        <h4 class="fs-15 fw-semibold">{{ $t('searchWorker') }}</h4>
                    </div>
                    <b-form class="kb-search-input" style="max-width: 500px;">
                        <b-input-group class="input-group-merge">
                            <b-form-input
                            id="searchbar"
                            @input="searchData($event)"
                            :placeholder="$t('search_word')"
                            />
                        </b-input-group>
                    </b-form>
                    <div :class="`mt-1 dropdown-menu dropdown-menu-lg ${this.filterWorkers.length > 0 && this.searching.length > 0 ? 'show': ''}`"  >
                        <div class="scroll_s">
                            <div class="dropdown-header" >
                            <h5 class="text-overflow text-muted mb-0 text-uppercase"><b>{{ $t('SearchResults') }}</b></h5>
                            <!-- {{ searchPosts }} -->
                            </div>
                            <div v-for="item, idx in filterWorkers" :key="idx" lass="card-header align-items-center d-flex border-bottom-dashed" style="padding:0.5rem 1rem!important">
                                <h4 @click="open(item)" class="card-title mb-0 flex-grow-1 copy history_of_numbers" style="display: inline-block; width: 80%;">{{item.workerName}}</h4>
                                <span class="badge text-bg-secondary" style="display: inline-block;" :style="this.form.sharePerms.find(el => el.workerId == item.workerId) != undefined ? 'background-color: red' : 'background-color: green'"> {{this.form.sharePerms.find(el => el.workerId == item.workerId) != undefined ? this.$t('Edit') : this.$t('Add')}} </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 mb-2 hstack gap-sm-1 gap-1 align-items-center flex-wrap email-topbar-link">
                        <!-- <span class="copy badge bg-success fs-14" @click="selectAll">{{ $t('SelectAll') }}</span>
                        <span class="copy badge bg-danger fs-14" @click="removeAll">{{ $t('RemoveAll') }}</span> -->
                    </div>
                    <div class="mb-2 d-flex align-items-center" >
                        <div class="me-2" >
                            <h5 class="mb-0 fs-16 fw-semibold mb-2">{{ $t('selectedWorkers') }} :</h5>
                            <template v-for="item in objItem.sharePerms" :key="item">
                                <span class="badge bg-info fs-12 m-1" style="margin-right: 5px;" @click="open(item)">{{ nameWorker(item.workerId) }}</span>
                            </template>
                            <template v-for="item in workerslist" :key="item">
                                <span v-if="item.checked" class="badge bg-info fs-12 m-1" style="margin-right: 5px;" @click="open(item)">{{ item.workerName }}</span>
                            </template>
                        </div>
                    </div>
                    <!-- <div style="max-height: 257px; overflow: auto;" >
                        <ul class="list-group list-group-flush border-dashed" v-if="filterWorkers.length > 0">
                            <li class="ps-0 pb-3" v-for="(item, index) in filterWorkers" :key="index">
                                <div class="d-flex align-items-start" >
                                    <div class="flex-grow-1" >
                                        <h5 class="fs-13 mb-0 copy" @click="this.$emit('open', item.workerId)">{{item.workerName}}</h5>
                                    </div>
                                    <div class="flex-shrink-0 ms-2">
                                        <button type="button" :class="`btn btn-${item.checked || checkWorker(item.workerId) ? 'danger' : 'success' } btn-sm`" @click="item.checked || checkWorker(item.workerId) ? remove(index, item) : add(item)">{{ item.checked || checkWorker(item.workerId) ? $t('Delete') : $t('Add') }}</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <b v-else>{{ $t('noWorkersWithName') }}</b>
                    </div> -->
                </b-col>
                <!-- може ще щось -->
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <div class="pt-2">
            <button type="button" class="btn btn-success" v-on:click="toShare">{{ $t('toShare') }}</button>
            <!-- <button type="button"  class="btn btn-success" v-on:click="edit">{{ $t('Edit') }}</button> -->
            </div>
        </template>
    </modal>

    <choosePerms @close="this.choosePermsShow = false" :worker="this.worker" @save="save" v-if="this.choosePermsShow === true" :file="this.form"></choosePerms>
</template>

<script>
import choosePerms from './choosePerms.vue';
import modal from '@/components/modal/dialog'
import { fileManager } from "@/API"
import { storeS } from '@/store';
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiServe = new fileManager();

export default{
    props: ['objItem'],
    components: {
        modal,
        choosePerms
    },
    data(){
        return{
            choosePermsShow: false,
            worker: '',
            form: '',
            permFolder: {
                shareWorkerPerms: [],
                removeShareWorkerIds: []
            },
            workerslist: [],
            selectPerm: 0,
            objWorkers: [],
            search: '',
            permlist: [
                { label: this.$t('YouCanViewUloadFiles'), value: 1 },
                { label: this.$t('YouCanEditUloadFiles'), value: 2 },
            ]
        }
    },
    created(){
        this.form = this.objItem;
        console.log('f', this);
        for(var item in this.workers){
            this.workerslist.push({
                workerId: this.workers[item].workerId,
                workerName: this.workers[item].workerName,
                checked: false
            })
        }

    },
    mounted() {
        console.log(storeS)
        storeS.checkModal.type = 'actionFM';
        storeS.checkModal.id = this.form.fileManagerId;
    },
    methods: {
        save(e){
            this.choosePermsShow = false
            if(e.perm == 'delete') {
                console.log('here')
                this.permFolder.removeShareWorkerIds.push(e.workerId)
            } else {
                console.log('here')
                this.permFolder.shareWorkerPerms.push({
                    workerId: e.workerId,
                    perms: [e.perm]
                })
            }
        },
        open(e) {
            e.workerName = this.nameWorker(e.workerId)
            this.worker = e
            this.choosePermsShow = true
        },
        searchData(e){
            this.search = e
        },
        add(e){
            e.checked = true
            this.permFolder.removeShareWorkerIds.forEach((element, index) => {
                if(element == e.workerId){
                    this.permFolder.removeShareWorkerIds.splice(index, 1)
                }
            });
        },
        remove(index, item){
            this.workerslist[index].checked = false
            this.objItem.sharePerms.forEach((element, idx) => {
                console.log(idx, element)
                if(element.workerId == item.workerId){
                    this.objItem.sharePerms.splice(idx, 1)
                }
            });
            this.permFolder.removeShareWorkerIds.push(item.workerId)
        },
        toShare(){
            apiServe.share(this.form.fileManagerId, this.permFolder).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FolderAccessSent'))
                    this.$emit('close')
                }
            })
        },
        checkWorker(item){
            var checkPerm = "";
            this.objItem.sharePerms.forEach(element => {
                if(element.workerId == item){
                    checkPerm = true
                }
            });
            return checkPerm
        },
        nameWorker(item){
            return nameWorker(item)
        },
    },
    computed: {
        filterWorkers: function() {
            return Object.values(this.workerslist)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        workers(){
            return storeS.workers
        },
        searching() {
            return this.search
        }
    }
}
</script>