<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="fileInfoBar" aria-labelledby="fileInfoBarLabel">
        <div class="offcanvas-header border-bottom">
            <h5 id="fileInfoBarLabel" style="margin-bottom:0px">{{ currentFile.type == 'folder' || currentFile.parentFolderId == null ? $t('FolderPreview') : $t('FilePreview') }}</h5>
            <div >
                <button type="button" @click="currentFile.favorite == 0 ? $emit('addToFavorites', currentFile) : $emit('removeFromFavorites', currentFile)" :class="`btn btn-ghost-primary btn-icon btn-sm fs-16 favourite-btn me-1 ${currentFile.favorite == 0 ? '' : 'active'}`">
                    <i class="ri-star-fill align-bottom"></i>
                </button>
                <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-overview">
                    <i class="ri-close-fill align-bottom"></i>
                </button>
            </div>
        </div>
        <div class="offcanvas-body">
            <div id="file-overview" class="h-100" style="display: block;">
                <!-- зображення  -->
                <div class="pb-3 border-bottom border-bottom-dashed mb-3" >
                    <div class="file-details-box bg-light p-3 text-center rounded-3 border border-light mb-3" >
                        <div class="display-4 file-icon" >
                            <i class="align-bottom " :class="mutateFileManager(currentFile.type).icon + ' text-' + mutateFileManager(currentFile.type).color"></i>
                        </div>
                    </div>
                    <button type="button" class="btn btn-icon btn-sm btn-ghost-success float-end fs-16" @click="$emit('share', this.currentFile)"><i class="ri-share-forward-line"></i></button>
                    <h5 class="fs-16 mb-1 file-name">{{ currentFile.name }}</h5>
                    <p class="text-muted mb-0 fs-12"><span class="create-date">{{ currentFile.createDatetime }}</span></p>
                </div>
                <!-- опис  -->
                <div >
                    <h5 class="fs-12 text-uppercase text-muted mb-3">{{ currentFile.type == 'folder' ? $t('descFolder') : $t('descFile') }}:</h5>
                    <div class="table-responsive" >
                        <table class="table table-borderless table-nowrap table-sm">
                            <tbody>
                                <tr>
                                    <th scope="row" style="width: 35%;">{{ $t('fileName') }}:</th>
                                    <td class="file-name">{{ currentFile.name }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{{ $t('сreatedT') }} :</th>
                                    <td class="create-date">{{ currentFile.createDatetime }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{{ $t('created') }} :</th>
                                    <td class="create-date">{{ nameWorker(currentFile.createWorkerId) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="mb-3">
                        <h5 class="fs-12 text-uppercase text-muted mb-2">{{ this.$t('desc') }}</h5>
                        <span v-html="currentFile.description" class="description"></span>
                    </div>

                    <div v-if="currentFile.sharePerms && currentFile.sharePerms.length > 0">
                        <h5 class="fs-12 text-uppercase text-muted mb-3">{{ $t('WhoHasAccess') }}:</h5>
                        <div class="table-responsive" >
                            <table class="table table-borderless table-nowrap table-sm">
                                <tbody>
                                    <template v-for="item in currentFile.sharePerms" :key="item">
                                        <tr>
                                            <th scope="row" style="width: 35%;">{{ nameWorker(item.workerId) }}</th>
                                            <td :class="`share-name fs-12 badge badge-soft-${item.perm == 1 ? 'info' : 'secondary' }`">{{ item.perm == 1 ? $t('ViewandDownload') : $t('EditingandUploading') }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="offcanvas-foorter p-3 text-center">
            <div class="mt-auto border-top border-top-dashed py-3" >
                <div class="hstack gap-2" >
                    <button type="button" v-if="(currentFile.hasOwnProperty('type') && currentFile.type != 'folder') || (currentFile.parentFolderId != null)" @click="downloadFile(currentFile)" class="btn btn-soft-info w-100"><i class="ri-download-2-line align-bottom me-1"></i> {{ $t('Download') }}</button>
                    <button type="button" @click="$emit('deleteItem', currentFile)" class="btn btn-soft-danger w-100 remove-file-overview"><i class="ri-close-fill align-bottom me-1"></i> {{ $t('Delete') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mutateFileManager, getMonth } from "@/usabilityScripts/globalMutate.js"
import { nameWorker } from '@/usabilityScripts/getNameWorker'

export default{
    props: ['currentFile'],
    data(){
        return{
            form: ''
        }
    },
    created(){
        console.log('th', this);
    },
    methods: {
        currentDate(e) {
            var date = new Date(e);
            var dateStr = {
                day: ("00" + (date.getDate())).slice(-2),
                month: (this.month(date.getMonth())),
                year: date.getFullYear()
            }
            return dateStr
        },
        month(e) {
            return getMonth(e)
        },
        mutateFileManager(e){
            return mutateFileManager(e)
        },
        nameWorker(item){
            return nameWorker(item)
        },
    },
    computed: {
        perms(){
            return storeS.perms
        }
    }
}
</script>