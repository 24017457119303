<template>
    <button 
        type="button" 
        id="grid-view-button" 
        :class="`btn btn-soft-info nav-link btn-icon fs-14 filter-button`"
        data-bs-toggle="offcanvas"
        data-bs-target="#myDrive" 
        aria-controls="myDrive"
    >
        <i :class="`${hideMenu == false ? 'bx bx-hide' : 'ri-menu-2-line'}`"></i>
    </button>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="myDrive" aria-labelledby="myDriveLabel">
        <div class="offcanvas-header border-bottom">
            <h5 id="myDriveLabel">{{ $t('fileManager') }}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="mx-n3 email-menu-sidebar-scroll" >
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <span class="fw-semibold fs-14">{{ $t('main') }}</span>
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body pt-0">
                                    <div class="mail-list mt-1" >
                                        <a role="button" :class="`collapsed fs-16 ${activeListItem == 'main' ? 'active' : ''}`" @click="$emit('getdata')">
                                            <i class="ri-folder-2-line align-bottom me-2 fs-20"></i> <span class="file-list-link">{{ $t('MyFiles') }}</span>
                                        </a>
                                        <a role="button" :class="`collapsed fs-16 ${activeListItem == 'lastDown' ? 'active' : ''}`" @click="$emit('lastDownloads')">
                                            <i class="bx bx-cloud-upload align-bottom me-2 fs-20"></i> <span class="file-list-link">{{ $t('LatestDownloads') }}</span>
                                        </a>
                                        <a role="button" :class="`collapsed fs-16 ${activeListItem == 'deleted' ? 'active' : ''}`" @click="$emit('getDeleted')">
                                            <i class="bx bx-trash align-bottom me-2 fs-20"></i> <span class="file-list-link">{{ $t('Basket') }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion accordion-flush" id="accordionFlushExample1">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <span class="fw-semibold fs-14">{{ $t('Cooperation') }}</span>
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample1">
                                <div class="accordion-body pt-0">
                                    <div class="mail-list mt-1" >
                                        <a role="button" :class="`collapsed fs-16 ${activeListItem == 'shared' ? 'active' : ''}`" @click="$emit('getSharedWith')">
                                            <i class="ri-team-fill align-bottom me-2 fs-20 "></i> <span class="file-list-link">{{ $t('sharedWithMe') }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion accordion-flush" id="accordionFlushExample3" v-if="favorites.length > 0">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <span class="fw-semibold fs-14">{{ $t('Favorites') }}</span>
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample3">
                                <div class="accordion-body pt-0">
                                    <div class="mail-list mt-1" >
                                        <template v-for="item in favorites" :key="item">
                                            <a role="button" :class="`collapsed fs-16 ${activeListItem == item.fileManagerId ? 'active' : ''}`" @click="$emit('openFolder', item, 'favorites')">
                                                <i class="ri-folder-2-line align-bottom me-2 fs-20"></i> <span class="file-list-link"> {{ item.name }}</span>
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            
        </div>
        <div class="offcanvas-foorter p-3 text-center">
            <div class="mt-auto border-top pt-4">
                <h6 class="fs-11 text-muted text-uppercase mb-3">{{ $t('Storage') }}</h6>
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                        <i class="ri-database-2-line fs-17"></i>
                    </div>
                    <div class="flex-grow-1 ms-3 overflow-hidden">
                        <div class="progress mb-2 progress-sm">
                            <div class="progress-bar bg-success" role="progressbar" :style="`width: ${dataDisk.Capacity}`" :aria-valuenow="`${dataDisk.Capacity}`" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span class="text-muted fs-12 d-block text-truncate" style="text-align: left;"><b>{{ dataDisk.Used }}</b> {{ $t('usedFrom') }} <b>{{ dataDisk.Size }}</b></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props: ['favorites', 'dataDisk', 'activeListItem'],
    data(){
        return{
            form: ''
        }
    }
}
</script>