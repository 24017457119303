<template>
    <modal @close="$emit('close')">
        <template v-slot:title><i class="ri-folders-line me-2 align-bottom text-muted"></i>{{ $t('moving') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="team-profile-img d-flex mb-4 " style="align-items: center;" >
                        <div class="avatar-md" >
                            <div class="avatar-title bg-soft-info bg-info-subtle text-info rounded fs-24" >
                                <i class="ri-folder-2-fill"></i>
                            </div>
                        </div>
                        <div class="team-content" style="margin-left: 15px;" >
                            <a class="member-name"> 
                                <h5 class="fs-16 mb-1">
                                    {{ form.name }}
                                </h5> 
                            </a>
                            <p class="text-muted member-designation mb-0">{{ form.createDatetime }}</p>
                        </div>
                    </div>
                </b-col>

                <ul class="nav nav-sm flex-column">
                    <template v-for="(item, index) in folderTree" :key="item">
                        <li class="nav-item">
                            <a :href="`#folder-${item.fileManagerId}`" class="nav-link collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="`folder-${item.fileManagerId}`" ><i class="ri-folder-line fw-semibold fs-16"></i> 
                                <span class="fs-16 fw-semibold" style="margin-left: 7px;">{{ item.name }}</span> <i class="bx bxs-chevron-right fs-16"></i>
                            </a>
                            <div class="menu-dropdown collapse" v-if="item.child" :id="`folder-${item.fileManagerId}`" style="">
                                <ul class="nav nav-sm flex-column">
                                    <template v-for="ch in item.child" :key="ch">
                                        <li class="nav-item">
                                            <a :href="`#folder-${ch.fileManagerId}`" class="nav-link collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="`folder-${ch.fileManagerId}`" >
                                                <i class="ri-folder-line fw-semibold fs-16"></i> <span class="fs-16 fw-semibold" style="margin-left: 7px;">{{ ch.name }}</span> <i class="bx bxs-chevron-right fs-16"></i>
                                            </a>
                                            <div v-if="ch.child" class="menu-dropdown collapse" :id="`folder-${ch.fileManagerId}`" >
                                                <ul class="nav nav-sm flex-column">
                                                    <template v-for="ch1 in ch.child" :key="ch1">
                                                        <!-- <li class="nav-item"><a href="#" class="nav-link">{{ ch1.name }}</a></li> -->
                                                        <li class="nav-item">
                                                            <a :href="`#folder-${ch1.fileManagerId}`" class="nav-link collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="`folder-${ch.fileManagerId}`" >
                                                                <i class="ri-folder-line fw-semibold fs-16"></i> <span class="fs-16 fw-semibold" style="margin-left: 7px;">{{ ch1.name }}</span> <i class="bx bxs-chevron-right fs-16"></i>
                                                            </a>
                                                            <div v-if="ch1.child" class="menu-dropdown collapse" :id="`folder-${ch1.fileManagerId}`" >
                                                                <ul class="nav nav-sm flex-column">
                                                                    <template v-for="ch2 in ch1.child" :key="ch2">
                                                                        {{ ch2 }}
                                                                        <li class="nav-item">
                                                                            <a :href="`#folder-${ch2.fileManagerId}`" class="nav-link collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="`folder-${ch2.fileManagerId}`" >
                                                                                <i class="ri-folder-line fw-semibold fs-16"></i> <span class="fs-16 fw-semibold" style="margin-left: 7px;">{{ ch2.name }}</span> <i class="bx bxs-chevron-right fs-16"></i>
                                                                            </a>
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </li>
                    </template>
                </ul>


                <b-col lg="12">
                </b-col>
                <!-- вибір куди перемістити -->
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <div class="pt-2">
            <button type="button" class="btn btn-success" v-on:click="move">{{ $t('transfer') }}</button>
            <!-- <button type="button"  class="btn btn-success" v-on:click="edit">{{ $t('Edit') }}</button> -->
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { fileManager } from "@/API"
import { storeS } from '@/store';

let apiServe = new fileManager();

export default{
    props: ['objItem'],
    components: {
        modal
    },
    data(){
        return{
            form: '',
            folderTree: '',
            objParams:{
                page: '1',
                pagelimit: '200',
                search: ''
            },
        }
    },
    created(){
        this.form = this.objItem;
        this.getdata();

        storeS.checkModal.type = 'actionFM';
        storeS.checkModal.id = this.form.fileManagerId;
    },
    methods: {
        add(){

        },
        getdata() {
            let mainFolders = []
            let childFolders = []
            this.folders.forEach(el => {
                if(el.parentFolderId === null) {
                    mainFolders.push({
                        name: el.name,
                        fileManagerId: el.fileManagerId,
                        child: []
                    })
                } else {
                    childFolders.push({
                        name: el.name,
                        fileManagerId: el.fileManagerId,
                        parent: el.parentFolderId,
                        child: []
                    })
                }
            })
            childFolders.forEach(el => {
                if(mainFolders.find(k => k.fileManagerId === el.parent) === undefined) {
                    if(childFolders.find(k => k.fileManagerId === el.parent)){
                        childFolders.find(k => k.fileManagerId === el.parent).child.push(el)
                    }
                    childFolders.splice(childFolders.indexOf(el),1)
                }
            })
            childFolders.forEach(el => {
                mainFolders.find(k => k.fileManagerId === el.parent).child.push(el)
            })
            console.log('mainFolders',mainFolders);
            this.folderTree = mainFolders
            // return mainFolders 
        }
    },
    computed: {
        folders(){
            return storeS.folders
        }
    }
}
</script>