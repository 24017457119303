<template>
    <!-- модалка подтверждения удаления -->
    <dialogmodal>
        <template v-slot:body>
          <div class="mt-4 text-center">
              <h3><b>{{ $t('toShare') }} - {{ this.file.name }}</b></h3>
          </div>
            <div class="mb-3">
                <input type="text" readonly class="form-control" id="exampleFormControlInput1" v-model="this.worker.workerName" placeholder="name@example.com" style="width: 65%; display: inline-block">
                <select class="form-control" style="width: 25%; display: inline-block; margin-left: 5%;" aria-label="Default select example" v-model="this.decision">
                    <option :value="'delete'">{{ this.$t('Delete') }}</option>
                    <option v-for="item, i in permlist" :key="i" :value="item.value">{{ item.label }}</option>
                </select>
            </div>
        </template>
        <template v-slot:footer-bottom>
              <div class="hstack gap-2 justify-content-center remove">
                  <button class="btn btn-link link-success fw-medium text-decoration-none" style="cursor: pointer;" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</button>
                  <button class="btn btn-danger" @click="remove(this.decision)">{{ $t('save') }}</button>
              </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'
import { storeS } from '@/store.js'

export default {
    props: ['worker', 'file'],
    components: {
        dialogmodal
    },
    data(){
        return{
            decision: 'delete',
            from: "",
            permlist: [
                { label: this.$t('YouCanViewUloadFiles'), value: 1 },
                { label: this.$t('YouCanEditUloadFiles'), value: 2 },
            ]
        }
    },
    created(){
        this.form = this.file
        storeS.checkModal.type = 'actionFM';
        storeS.checkModal.id = this.form.fileManagerId;
    },
    methods: {
        remove(e){
            this.$emit('save', {workerId: this.worker.workerId, perm: e})
        }
    },
    mounted(){
        
    }
}
</script>