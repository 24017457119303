<template>
    <modal @close="$emit('close')">
        <template v-slot:title><i class="ri-folder-add-line me-2 align-bottom text-muted"></i>{{ $t('createdFolder') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15 fw-semibold">{{ $t('enterNameFolder') }}</h4>
                        <input type="text" class="form-control" v-model="this.form.folderName">
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <div class="pt-2">
                <button type="button" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { fileManager } from "@/API"
import { storeS } from "@/store";

let apiServe = new fileManager();

export default{
    props: ['parrentId', 'permWorkers'],
    components: {
        modal
    },
    data(){
        return{
            form: {
                folderName: '',
                parentFolderId: null,
            }
        }
    },
    created() {
        // console.log('permWorkers', this)
        storeS.checkModal.type = 'actionFM';
        storeS.checkModal.id = Math.floor(Math.random() * (9999 - 10)) + 10;
    },
    methods: {
        add(){
            if(this.parrentId != '') {
                this.form.parentFolderId = this.parrentId
            }
            apiServe.createFolder(this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FolderCreatedSuccessfully'))
                    this.$emit('close')
                }
            })
        }
    }
}
</script>